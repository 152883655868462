import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'

import NewApp from './NewApp'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './app/store'
// import { persistor } from './app/store'
// import { PersistGate } from 'redux-persist/integration/react'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <NewApp />
    {/* </PersistGate> */}
  </Provider>,
  // </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
