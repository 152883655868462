import axios, { AxiosResponse } from 'axios'
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js' // Assuming you're using Amazon Cognito
import { AuthError } from '../redux_features/session/cogAccount'

const API_BASE_URL = process.env.REACT_APP_API_URL

export interface UserClaims {
  organizationId: string
  userId: string
  roles: string[]
  userSettings: Record<string, string>
}

export interface ClaimsResult {
  success: boolean
  userInfo?: UserClaims
  registrationComplete?: boolean
  error?: AuthError
}
// export const authenticateApi = async (
//   email: string,
//   password: string,
// ): Promise<ClaimsResult> => {
//   try {
//     const response: AxiosResponse<any> = await axios.post(
//       `${API_BASE_URL}/login`,
//       {
//         email,
//         password,
//       },
//       {
//         headers: {
//           Accept: 'application/json',
//         },
//       },
//     )

//     console.log('Login response:', response)

//     if (response.data.success) {
//       const session = new CognitoUserSession({
//         IdToken: new CognitoIdToken({ IdToken: response.data.tokens.idToken }),
//         AccessToken: new CognitoAccessToken({
//           AccessToken: response.data.tokens.accessToken,
//         }),
//         RefreshToken: new CognitoRefreshToken({
//           RefreshToken: response.data.tokens.refreshToken,
//         }),
//       })

//       return {
//         success: true,
//         session,
//       }
//     }

//     // Map the error code to the expected string literals
//     const errorCodeMap: Record<string, AuthError['code']> = {
//       auth_failed: 'auth_failed',
//       missing_organization: 'missing_organization',
//       changepass: 'changepass',
//       unknown_error: 'unknown_error',
//       new_passsword: 'new_passsword',
//     }

//     const errorCode = errorCodeMap[response.data.errorCode] || 'unknown_error'

//     return {
//       success: false,
//       error: {
//         code: errorCode,
//         message: response.data.errorMessage || 'Authentication failed',
//       },
//     }
//   } catch (error) {
//     console.error('Error during authentication:', error)
//     return {
//       success: false,
//       error: {
//         code: 'unknown_error',
//         message:
//           error instanceof Error ? error.message : 'An unknown error occurred',
//       },
//     }
//   }
// }

export const getAuthenticatedUser = async (
  email: string,
  accessToken: CognitoAccessToken,
): Promise<ClaimsResult> => {
  try {
    const response: AxiosResponse<any> = await axios.post(
      `${API_BASE_URL}/get-authenticated-user`,
      { email },
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )

    if (response.data.success) {
      return {
        success: true,
        userInfo: response.data.userInfo,
        registrationComplete: response.data.registrationComplete,
      }
    } else {
      return {
        success: false,
        error: {
          code: 'auth_failed',
          message: response.data.error || 'Failed to authenticate user',
        },
      }
    }
  } catch (error) {
    console.error('Error fetching authenticated user:', error)
    return {
      success: false,
      error: {
        code: 'unknown_error',
        message:
          error instanceof Error ? error.message : 'An unknown error occurred',
      },
    }
  }
}
