import React from 'react'

import MapJSContainer from './NewMapController/MapJSContainer'

function TestPage() {
  return (
    <div>
      <MapJSContainer />
    </div>
  )
}

export default TestPage
