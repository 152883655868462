import { useSelector } from 'react-redux'
import { RootState } from '../app/store'

const useAuthStatus = () => {
  const sessionStatus = useSelector((state: RootState) => state.session.status)
  const isLoggedIn = sessionStatus === 'succeeded'

  return { isLoggedIn, sessionStatus }
}

export default useAuthStatus
