import { Geofence } from './types'

import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL!

export type Why = {
  [bodyitem: string]: []
}

export const getGeofences = async (
  sessionToken: string,
  organizationId: string,
): Promise<Geofence[]> => {
  return new Promise((res) => {
    res(
      axios
        .get<any>(`${API_URL}/geofences`, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data['result']
        })
        .catch((reason) => {
          console.log('error getting geofences')
          return []
        }),
    )
  })
}

export const getGeofencesVehicles = async (
  geofenceid: string,
  sessionToken: string,
  organizationId: string,
): Promise<any> => {
  return new Promise((res) => {
    res(
      axios
        .get<any>(`${API_URL}/geofenceVehicles/${geofenceid}`, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data['result']
        }),
    )
  })
}

//TODO geofence alert type, geomet
export const addGeofence = async (
  geofence: Geofence,
  sessionToken: string,
  organizationId: string,
): Promise<any> => {
  return new Promise((res) => {
    res(
      axios
        .post<Geofence>(`${API_URL}/geofence`, geofence, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data
        }),
    )
  })
}

export const updateGeofence = async (
  geofence: Geofence,
  organizationId: string,
  sessionToken: string,
): Promise<Geofence> => {
  return new Promise((res) => {
    res(
      axios
        .patch<Geofence>(
          `${API_URL}/${organizationId}/geofences/${geofence.id}`,
          geofence,
          {
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + sessionToken,
            },
          },
        )
        .then((response) => {
          return response.data
        }),
    )
  })
}

export const removeGeofence = async (
  geofenceId: string,
  sessionToken: string,
  organizationId: string,
): Promise<any> => {
  return new Promise((res) => {
    res(
      axios
        .delete(`${API_URL}/geofences/${geofenceId}`, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data
        }),
    )
  })
}

export const updateGeofenceVehicles = async (
  data: any,
  geofenceid: string,
  organizationId: string,
  sessionToken: string,
) => {
  return new Promise((res) => {
    res(
      axios
        .patch(`${API_URL}/geofenceVehicles/${geofenceid}`, data, {
          params: { organizationId },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data
        }),
    )
  })
}

const f = (r: any): any => JSON.parse(r)

export const getGeoAccess = async (
  organizationId: string,
  startDate: Date | null,
  endDate: Date | null,
  sessionToken: string,
): Promise<any> => {
  //
  //

  const response = await axios
    .get<any>(`${API_URL}/geofenceAccess`, {
      params: { startDate, endDate, organizationId },

      transformResponse: f,
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + sessionToken,
      },
    })
    .then((response) => {
      //
      return response.data['result']
    })
    .catch((reason) => {
      console.log('Geofences acceses could not be fetched')
      return []
    })
}
