import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { OrganizationUser, User } from '../../api/types'

import { secureCompany, secureOrganizationUser } from '../../api/types'
import { addItem, getAll, updateItem } from '../../api/api_functions'

let users: OrganizationUser[] = []

let initialState = {
  users: users,
  status: 'idle',
  error: '',
}

export const fetchusers = createAsyncThunk(
  'users/fetchusers',

  async (obj: secureCompany) => {
    const response = await getAll<OrganizationUser>(
      'users',
      obj.token,
      obj.organizationId,
    )

    return response
  },
)

export const addNewuser = createAsyncThunk(
  'users/addNewuser',
  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureOrganizationUser) => {
    //
    const response = await addItem<OrganizationUser>(
      'users',
      obj.user,
      obj.token,
      obj.organizationId,
    )
    // The response includes the complete post object, including unique ID
    return obj.user // need to reutn the user to add to the redux  store
  },
)

export const userUpdated = createAsyncThunk(
  'users/userUpdated',

  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureOrganizationUser) => {
    // We send the initial data to the fake API server
    const response = await updateItem(
      'users',
      obj.user,
      obj.token,
      obj.organizationId,
    )

    // The response includes the complete post object, including unique ID
    return obj.user // need to reutn the vehcile to add to the redux  store
  },
)

export const deleteuser = createAsyncThunk(
  'users/deleteuser',
  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureOrganizationUser) => {
    //
    const response = await updateItem(
      'users',
      obj.user,
      obj.token,
      obj.organizationId,
    )
    // The response includes the complete post object, including unique ID
    return obj.user // need to reutn the user to add to the redux  store
  },
)

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchusers.pending, (state, action: PayloadAction) => {
        state.status = 'loading'
      })
      .addCase(fetchusers.fulfilled, (state, action) => {
        state.status = 'succeeded'

        // Add any fetched posts to the array

        state.users = state.users.concat(action.payload.result)
      })
      .addCase(fetchusers.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'users List could not be retrieved'
        // state.vehicles.error = action.error.message
        //
      })
      .addCase(addNewuser.fulfilled, (state, action) => {
        // We can directly add the new post object to our posts array ....always remember that inside create slice inmutability is respected due tu intrinsic use of immer

        state.users.push(action.payload)
      })
      .addCase(addNewuser.rejected, (state, action) => {
        state.status = 'failed'
      })

      .addCase(userUpdated.fulfilled, (state, action) => {
        const updatedUser: OrganizationUser = action.payload

        const existingUser = state.users.find(
          (user: Partial<OrganizationUser>) => user.id === updatedUser.id,
        )
        if (existingUser) {
          existingUser.user.name = updatedUser.user.name
        }
      })
      .addCase(deleteuser.fulfilled, (state, action) => {
        state.status = 'failed'
      })
  },
})
export const selectAllusers = (state: { users: { users: any } }) =>
  state.users.users

export default usersSlice.reducer
