import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../app/hooks'
import { RootState } from '../../app/store'
import { updateGeolocations } from '../../redux_features/geolocations/geolocationSlice'

export const useWebSocket = () => {
  const [notification, setNotification] = useState<string | null>(null)
  const socketRef = useRef<WebSocket | null>(null)
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const visibilityTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const dispatch = useAppDispatch()
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  const userId = useSelector(
    (state: RootState) => state.session.userdata.userinfo.userId,
  )

  const socketURL = process.env.REACT_APP_WS_URL!

  const open_msg = {
    action: 'message',
    msg: 'connection from web',
    organizationId: organizationId,
    userId: userId,
  }

  useEffect(() => {
    setupWebSocket()
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      cleanUpWebSocket()
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      if (visibilityTimeoutRef.current) {
        clearTimeout(visibilityTimeoutRef.current)
      }
    }
  }, [])

  const setupWebSocket = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      return // Already connected
    }

    socketRef.current = new WebSocket(socketURL)
    socketRef.current.addEventListener('open', onOpen)
    socketRef.current.addEventListener('message', onMessage)
    socketRef.current.addEventListener('close', onClose)
    socketRef.current.addEventListener('error', onError)

    // Initialize ping interval
    if (pingIntervalRef.current) clearInterval(pingIntervalRef.current)
    pingIntervalRef.current = setInterval(sendPing, 60000)
  }

  const cleanUpWebSocket = () => {
    if (socketRef.current) {
      socketRef.current.close()
      socketRef.current = null
    }
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current)
      pingIntervalRef.current = null
    }
  }

  const onOpen = (event: any) => {
    if (socketRef.current) socketRef.current.send(JSON.stringify(open_msg))
  }

  const onMessage = (event: any) => {
    // Handle WebSocket messages

    const data = JSON.parse(event.data)
    if (data.type === 'pong') {
      console.log('Connection is alive')
      // setPingStatus('OK')      //commented to check if performance is affected
    } else if (data.type === 'location') {
      const messageData: any[] = JSON.parse(data.message) /// review

      // Flatten currentData
      const flattenedData = messageData.map((item) => ({
        deviceId: item.deviceId,
        ...item.currentData,
      }))

      dispatch(updateGeolocations(flattenedData))
    } else if (data.type === 'notification') {
      console.log('Notification:', data.message)
      setNotification(data.message)
      setTimeout(() => setNotification(null), 5000)
    } else {
      console.log('unkonown default type', data)
    }
  }

  const onClose = () => {
    // Attempt to reconnect or handle the close
    // ...
    console.log('WebSocketClosed')
  }

  const onError = (error: any) => {
    // Handle WebSocket error and attempt to reconnect
    // ...
  }

  const sendPing = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ action: 'ping' }))
    } else {
      setupWebSocket() // Attempt to reconnect if not open
    }
  }

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      console.log('hidden setting timeout')
      visibilityTimeoutRef.current = setTimeout(() => {
        cleanUpWebSocket()
      }, 60000) // 1 minute timeout
    } else if (document.visibilityState === 'visible') {
      console.log('visible clearing timeout and reconnecting')
      if (visibilityTimeoutRef.current) {
        clearTimeout(visibilityTimeoutRef.current)
        visibilityTimeoutRef.current = null
      }
      setupWebSocket() // Re-establish WebSocket connection if needed
    }
  }
  return { notification }
}

export default WebSocket
