import React from 'react'

type NotProps = {
  message: string
}

const Notification = ({ message }: NotProps) => {
  return (
    <div className="fixed top-12 right-24 bg-blue-200 p-4 rounded-md shadow-lg z-50">
      {message}
    </div>
  )
}

export default Notification
