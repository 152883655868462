import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { selectAllVehicles } from '../../redux_features/vehicles/vehicleSlice'
import { MapControllerprops } from './mapTypes'
import { calculateMapCenterAndZoom } from '../../utils/mapUtils'
import { LastKnownData } from '../../api/types'

export function useMapController(props: MapControllerprops) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
  const [openRightDrawer, setOpenRightDrawer] = useState(false)
  const [rightDrawerMode, setRightDrawerMode] = useState<
    'geofence' | 'history' | '' | 'liveinfo' | 'event'
  >('')
  const [vehicleId, setVehicleId] = useState('')
  const [selectedTab, setSelectedTab] = useState('Vehicles')

  const vehicleStatus = useSelector((state: RootState) => state.vehicles.status)
  const geolocationStatus = useSelector(
    (state: RootState) => state.geolocations.status,
  )
  const vehicles = useSelector(selectAllVehicles)
  const livegeolocations = useSelector(
    (state: RootState) => state.geolocations.geolocations,
  )
  const livegeolocationsstatus = useSelector(
    (state: RootState) => state.geolocations.status,
  )

  // useEffect(() => {
  //   if (props.map) changeMapStyle(props.map, props.mapstyle)
  // }, [props.mapstyle, props.map])

  useEffect(() => {
    if (livegeolocationsstatus === 'succeeded')
      console.log('do something here maybe')
  }, [props.center, livegeolocationsstatus])

  const historyClick = (deviceId: string) => {
    setVehicleId(deviceId)
    setRightDrawerMode('history')
    setOpenRightDrawer(true)
  }

  const infoClick = (deviceId: string) => {
    setVehicleId(deviceId)
    setRightDrawerMode('liveinfo')
    setOpenRightDrawer(true)
  }

  const closeRightDrawer = () => {
    setOpenRightDrawer(false)
    setRightDrawerMode('')
  }

  const handleCardClick = (item: LastKnownData) => {
    const center: google.maps.LatLngLiteral = {
      lat: item.latitude,
      lng: item.longitude,
    }
    setVehicleId(item.deviceId)
    props.map?.setCenter(center)
  }

  const fitall = () => {
    const zoomcenter = calculateMapCenterAndZoom(livegeolocations)
    setIsCollapsed(true)
    props.map?.setZoom(zoomcenter.zoom)
    props.map?.setCenter({
      lat: zoomcenter.center.lat,
      lng: zoomcenter.center.lng,
    })
  }

  return {
    mapControllerState: {
      isCollapsed,
      openRightDrawer,
      rightDrawerMode,
      vehicleId,
      selectedTab,
      vehicleStatus,
      geolocationStatus,
      vehicles,
      livegeolocations,
      livegeolocationsstatus,
    },
    handlers: {
      setSelectedTab,
      historyClick,
      infoClick,
      closeRightDrawer,
      handleCardClick,
      fitall,
    },
    mapProps: props,
  }
}
