import React, { useState, useEffect, useMemo } from 'react'
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

type PasswordValidatorProps = {
  password: string
  enableFunction?: () => void
}

const PasswordValidatorComponent: React.FC<PasswordValidatorProps> = (
  props: PasswordValidatorProps,
) => {
  const [conditionsArray, setConditionsArray] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ])

  const checkLength = useMemo(() => (str: string) => str.length >= 8, [])
  const hasLowerCase = useMemo(() => (str: string) => /[a-z]/.test(str), [])
  const hasUpperCase = useMemo(() => (str: string) => /[A-Z]/.test(str), [])
  const hasNumber = useMemo(() => (str: string) => /[0-9]/.test(str), [])
  const hasSpecialChar = useMemo(
    () => (str: string) => /[-+_!@#$%^&*.,?]/.test(str),
    [],
  )

  const checkAll = () => {
    const newConditionsArray = [
      checkLength(props.password),
      hasLowerCase(props.password),
      hasUpperCase(props.password),
      hasNumber(props.password),
      hasSpecialChar(props.password),
    ]
    setConditionsArray(newConditionsArray)

    // Check if all conditions are met
    if (newConditionsArray.every((condition) => condition)) {
      props.enableFunction?.()
    }
  }

  useEffect(() => {
    checkAll()
  }, [props.password])

  const passwordRequirements: string[] = [
    '8-character minimum length',
    'Contains at least 1 lowercase letter',
    'Contains at least 1 uppercase letter',
    'Contains at least 1 number',
    'Contains at least 1 special character (-+_!@#$%^&*.,?)',
  ]

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Password Requirements:
      </Typography>
      <List dense>
        {passwordRequirements.map((req, index) => (
          <ListItem key={index} sx={{ py: 0 }}>
            <ListItemIcon sx={{ minWidth: 36 }}>
              {conditionsArray[index] ? (
                <CheckCircleIcon color="success" fontSize="small" />
              ) : (
                <RadioButtonUncheckedIcon color="disabled" fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={req}
              primaryTypographyProps={{
                variant: 'body2',
                color: conditionsArray[index] ? 'textPrimary' : 'textSecondary',
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default PasswordValidatorComponent
