import { useState } from 'react'

export function useNewGeofence() {
  const [newGeoMode, setNewGeoMode] = useState(false)

  const [newGeofenceName, setNewGeofenceName] = useState('')

  const [geofenceLinkedVehicles, setGeofenceLinkedVehicles] = useState<
    string[]
  >([])

  return {
    newGeoMode,
    newGeofenceName,
    geofenceLinkedVehicles,
    setNewGeoMode,
    setNewGeofenceName,
    setGeofenceLinkedVehicles,
  }
}
