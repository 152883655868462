import { useState, useEffect, useCallback } from 'react'
import * as React from 'react'
import GeofenceNew from './GeofenceNew'
import { Button, Divider } from '@mui/material'
import { Geofence, GeofenceWithFigure } from '../../api/types'
import { RootState } from '../../app/store'
import GeofenceEdit from './GeofenceEdit'
import RightDrawer from '../RightDrawer'
import AddIcon from '@mui/icons-material/Add'

import GeofencesList from './GeofencesList'
import { useDrawingManager } from './useDrawingManager'
import { useGeofenceFetching } from './useGeofenceFetching'
import { useGeofenceEditing } from './useGeofenceEditing'
import { useNewGeofence } from './useNewGeofence'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectAllGeofencesWithFigures } from '../../redux_features/geofences/geofenceSlice'
import { useTopBar } from '../../components/Layout/TopBarContext'
import {
  deleteGeofence,
  createGeofence,
} from '../../redux_features/geofences/geofenceSlice'

export interface Theme {}
export interface MapControllerprops {
  map: google.maps.Map | undefined
  selectedTab: string
}

function GeofencesController(props: MapControllerprops) {
  const dispatch = useAppDispatch()

  const sessionToken = useAppSelector(
    (state: RootState) => state.session.userdata.accesstoken,
  )
  const organizationId = useAppSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )

  const [GeofenceLinkedVehicles, setLinkedVehicles] = React.useState<any>([])

  const defaultColor: google.maps.RectangleOptions = { fillColor: '#000000' }
  const editColor: google.maps.RectangleOptions = { fillColor: '#FF0000' }

  //newgeofence
  const {
    newGeoMode,
    newGeofenceName,
    geofenceLinkedVehicles,
    setNewGeoMode,
    setNewGeofenceName,
    setGeofenceLinkedVehicles,
  } = useNewGeofence()

  //editmode
  const {
    editMode,
    editIndex,
    onEdit,
    origBounds,
    editedCoordinates, //this is actually original coordinates
    findGeofenceById,
    setupGeofenceForEditing,
    updateGeofence,
    cancelEditing,
    setEditMode,
    setEditedCoordinates,
    setEditIndex,
    setOrigBounds,
    setOnEdit,
  } = useGeofenceEditing(sessionToken, organizationId)

  const { drawingManager, newShape, startDrawing } = useDrawingManager(
    props.map,
  )
  const { allGeofences } = useGeofenceFetching()
  const geofencesWithFigures = useAppSelector((state: RootState) =>
    selectAllGeofencesWithFigures(state),
  )
  const { setTopBarContent } = useTopBar()

  useEffect(() => {
    console.log('Drawing Manager initialized:', drawingManager)
    if (props.map && props.selectedTab === 'Geofences') {
      SetAllGeofencesOnMap(geofencesWithFigures, props.map)
    } else SetAllGeofencesOnMap(geofencesWithFigures, null)

    return () => {
      SetAllGeofencesOnMap(geofencesWithFigures, null)
    }
  }, [props.selectedTab, drawingManager, geofencesWithFigures])

  useEffect(() => {
    setTopBarContent(
      <Button
        variant="contained"
        color="primary"
        endIcon={<AddIcon />}
        onClick={() => {
          console.log('Map:', props.map, 'Drawing Manager:', drawingManager)
          if (props.map) setManagerOnMap(props.map)
        }}
      >
        Add Geofence
      </Button>,
    )
    // Clean up function to reset the TopBar content when unmounting
    return () => setTopBarContent(null)
  }, [setTopBarContent, props.map, drawingManager])

  const setSingleGeofenceOnMap = (
    element: GeofenceWithFigure,
    map: google.maps.Map | null,
  ) => {
    const infoWindow = new google.maps.InfoWindow()

    const updatePolygonCoordinates = () => {
      if (element.figure instanceof google.maps.Polygon) {
        const path = element.figure.getPath()
        const coordinates = path
          .getArray()
          .map((latLng) => [latLng.lng(), latLng.lat()])
        setEditedCoordinates(coordinates)
      }
    }

    const handleBoundsChange = () => {
      let bounds: google.maps.LatLngBounds
      let ne: google.maps.LatLng
      let sw: google.maps.LatLng

      if (element.figure instanceof google.maps.Rectangle) {
        bounds = element.figure.getBounds()!
        ne = bounds.getNorthEast()
        sw = bounds.getSouthWest()
      } else if (element.figure instanceof google.maps.Polygon) {
        bounds = new google.maps.LatLngBounds()
        element.figure.getPath().forEach((latLng) => bounds.extend(latLng))
        ne = bounds.getNorthEast()
        sw = bounds.getSouthWest()
      } else {
        console.error('Unsupported geofence type')
        return
      }

      infoWindow.setContent(element.name + ne.toString())
      infoWindow.setPosition(ne)
      infoWindow.open(map)

      const updcoordinateArr = [
        [sw.lng(), sw.lat()],
        [ne.lng(), sw.lat()],
        [ne.lng(), ne.lat()],
        [sw.lng(), ne.lat()],
        [sw.lng(), sw.lat()],
      ]

      setEditedCoordinates(updcoordinateArr)
    }

    element.figure.addListener('click', handleBoundsChange)

    if (element.figure instanceof google.maps.Rectangle) {
      element.figure.addListener('bounds_changed', handleBoundsChange)
    } else if (element.figure instanceof google.maps.Polygon) {
      element.figure
        .getPath()
        .addListener('insert_at', updatePolygonCoordinates)
      element.figure
        .getPath()
        .addListener('remove_at', updatePolygonCoordinates)
      element.figure.getPath().addListener('set_at', updatePolygonCoordinates)
      element.figure.addListener('dragend', updatePolygonCoordinates)
    }

    element.figure.setMap(map)
    //
  }

  const SetAllGeofencesOnMap = (
    array: GeofenceWithFigure[],
    map: google.maps.Map | null,
  ) => {
    array.forEach((element, index) => {
      setSingleGeofenceOnMap(element, map)
    })
  }

  const SaveNewGeofence = useCallback(() => {
    if (newShape && newGeofenceName !== '') {
      let coordinates: number[][]
      let type: string
      if (newShape instanceof google.maps.Rectangle) {
        const bounds = newShape.getBounds()!
        coordinates = [
          [bounds.getSouthWest().lng(), bounds.getSouthWest().lat()],
          [bounds.getNorthEast().lng(), bounds.getSouthWest().lat()],
          [bounds.getNorthEast().lng(), bounds.getNorthEast().lat()],
          [bounds.getSouthWest().lng(), bounds.getNorthEast().lat()],
          [bounds.getSouthWest().lng(), bounds.getSouthWest().lat()],
        ]
        type = 'Rectangle'
      } else if (newShape instanceof google.maps.Polygon) {
        coordinates = newShape
          .getPath()
          .getArray()
          .map((latLng) => [latLng.lng(), latLng.lat()])
        coordinates.push(coordinates[0]) // Close the polygon
        type = 'Polygon'
      } else {
        alert('Invalid shape')
        return
      }

      const tempGeofence: Partial<Geofence> = {
        name: newGeofenceName,
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
        type: type,
      }
      dispatch(
        createGeofence({
          geofence: tempGeofence,
          organizationId,
          token: sessionToken,
        }),
      )
        .unwrap()
        .then(() => {
          setNewGeoMode(false)
          newShape?.setDraggable(false)
          newShape?.setEditable(false)
          newShape.setOptions(defaultColor)
          newShape.setMap(null)
        })
        .catch((error) => {
          alert('Failed to create geofence: ' + error.message)
        })
    } else {
      alert('Please draw a figure and provide a name for the new geofence')
    }
  }, [newShape, newGeofenceName, dispatch, organizationId, sessionToken])

  const deleteGeofenceCallback = useCallback(
    (id: string) => {
      const tempGeo = geofencesWithFigures.find(
        (value: GeofenceWithFigure) => value.id === id,
      )

      dispatch(
        deleteGeofence({
          geofence: { id },
          token: sessionToken,
          organizationId: organizationId,
        }),
      )
        .unwrap()
        .then(() => {
          // Show success message
          if (tempGeo) setSingleGeofenceOnMap(tempGeo, null)

          alert('Geofence deleted successfully')
        })
        .catch((error) => {
          // Show error message
          alert('Failed to delete geofence: ' + error.message)
        })
    },
    [dispatch, sessionToken, organizationId],
  )
  const setupForEdition = (id: string) => {
    console.log(id)
    const tempGeo = geofencesWithFigures.find(
      (value: GeofenceWithFigure) => value.id === id,
    )

    const geofenceIndex = geofencesWithFigures.findIndex(
      (value) => value.id === id,
    )
    console.log(tempGeo)
    if (!editMode && !newGeoMode && tempGeo) {
      setOnEdit({ ...geofencesWithFigures[geofenceIndex] })
      setEditMode(true)

      setEditIndex(geofenceIndex)
      if (tempGeo.figure instanceof google.maps.Rectangle) {
        const sw = tempGeo.figure.getBounds()!.getSouthWest()
        const ne = tempGeo.figure.getBounds()!.getNorthEast()
        const beforeEditingBounds = tempGeo.figure.getBounds()

        const originalCoordinates = [
          [sw.lng(), sw.lat()],
          [ne.lng(), sw.lat()],
          [ne.lng(), ne.lat()],
          [sw.lng(), ne.lat()],
          [sw.lng(), sw.lat()],
        ]
        setEditedCoordinates(originalCoordinates)
        setOrigBounds(beforeEditingBounds)
      } else if (tempGeo.figure instanceof google.maps.Polygon) {
        const bounds = new google.maps.LatLngBounds()
        const path = tempGeo.figure.getPath()
        path.forEach((latLng) => bounds.extend(latLng))
        const originalCoordinates = path
          .getArray()
          .map((latLng) => [latLng.lng(), latLng.lat()])
        console.log(originalCoordinates)
        setEditedCoordinates(originalCoordinates)

        setOrigBounds(bounds)
      }

      geofencesWithFigures.forEach((element) => {
        element.figure.setOptions(defaultColor)
      })

      tempGeo.figure.setOptions(editColor)
      tempGeo.figure.setDraggable(true)
      tempGeo.figure.setEditable(true)
    } else {
      alert('Please save or disregard your changes first.')
    }
  }

  const setManagerOnMap = (map: google.maps.Map) => {
    if (drawingManager) {
      drawingManager.setMap(map)
      setNewGeoMode(true)
    } else alert('There has been an error drawing your geofence')
  }

  const CancelAll = () => {
    if (editIndex != null) {
      const figure = geofencesWithFigures[editIndex].figure
      figure.setDraggable(false)
      figure.setEditable(false)
      figure.setMap(null)
      if (props.map && figure instanceof google.maps.Rectangle) {
        figure.setBounds(origBounds)
        figure.setMap(props.map)
      }
    }
    setEditIndex(null)
    setNewGeoMode(false)
    setEditMode(false)
    setLinkedVehicles([])
    drawingManager?.setMap(null)
  }

  //for future use
  const handleToggleNew = (value: string) => () => {
    const newChecked = [...GeofenceLinkedVehicles]
    if (GeofenceLinkedVehicles?.includes(value)) {
      const index = newChecked.indexOf(value)
      newChecked.splice(index, 1)
    } else newChecked.push(value)

    setLinkedVehicles(newChecked)
  }

  const NewGeoNameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewGeofenceName(e.target.value)
  }

  return (
    <>
      <GeofencesList
        GeoFenceArray={allGeofences}
        map={props.map}
        handleEdit={setupForEdition}
        deleteGeofence={deleteGeofenceCallback}
        hideButton={editMode}
      />
      {newGeoMode && (
        <RightDrawer anchor={'right'} close={CancelAll}>
          <GeofenceNew
            startDrawing={startDrawing}
            newShape={newShape}
            CancelAll={CancelAll}
            NewGeoNameChanged={NewGeoNameChanged}
            SaveNewGeofence={SaveNewGeofence}
            handleToggleNew={handleToggleNew}
            GeofenceLinkedVehicles={GeofenceLinkedVehicles}
          />
        </RightDrawer>
      )}

      {editMode && (
        <RightDrawer anchor={'right'} close={CancelAll}>
          <Divider />
          <GeofenceEdit
            cancelEditing={cancelEditing}
            updateGeofence={updateGeofence}
            geofenceToEdit={onEdit}
          />
          <Divider />
        </RightDrawer>
      )}
    </>
  )
}

export default GeofencesController
