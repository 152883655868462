import React from 'react'
import { useMapController } from './useMapController'
import DesktopView from './DesktopView'
import MobileView from './MobileView'
import MapControls from './MapControls'

import useIsMobile from '../../customHooks/useIsMobile'
import { MapControllerprops } from './mapTypes'
import { useMapLiveMarkers } from './useMapLiveMarkers'

function MapController(props: MapControllerprops) {
  const { mapControllerState, handlers, mapProps } = useMapController(props)

  const isMobile = useIsMobile()
  useMapLiveMarkers(props.map)
  return (
    <>
      <MapControls onFitAll={handlers.fitall} />
      {isMobile ? (
        <MobileView
          state={mapControllerState}
          handlers={handlers}
          mapProps={mapProps}
        />
      ) : (
        <DesktopView
          state={mapControllerState}
          handlers={handlers}
          mapProps={mapProps}
        />
      )}
    </>
  )
}

export default MapController
