import React from 'react'

interface MapControlsProps {
  onFitAll: () => void
}

const MapControls: React.FC<MapControlsProps> = ({ onFitAll }) => {
  return (
    <div
      style={{
        position: 'fixed',
        right: '10px',
        top: '250px',
        zIndex: 1,
      }}
    >
      <span
        onClick={onFitAll}
        className="material-symbols-outlined"
        style={{ fontSize: '40px', color: '#1976d2', cursor: 'pointer' }}
      >
        fit_screen
      </span>
    </div>
  )
}

export default MapControls
