import React from 'react'
import { Alert, AlertTitle, Stack } from '@mui/material'

const mockAlerts = [
  {
    severity: 'error',
    title: 'Critical Issue',
    message: 'Vehicle #1234 has reported an engine failure.',
  },
  {
    severity: 'warning',
    title: 'Maintenance Required',
    message: 'Vehicle #5678 is due for scheduled maintenance.',
  },
  {
    severity: 'info',
    title: 'Route Update',
    message: 'New optimal route calculated for Driver #91011.',
  },
]

function Alerts() {
  return (
    <Stack spacing={2}>
      {mockAlerts.map((alert, index) => (
        <Alert
          severity={alert.severity as 'error' | 'warning' | 'info' | 'success'}
          key={index}
        >
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}
        </Alert>
      ))}
    </Stack>
  )
}

export default Alerts
