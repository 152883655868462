import axios from 'axios'

import { Entity } from './types'
const API_BASE_URL = process.env.REACT_APP_API_URL

export interface Response<T> {
  message?: string
  result: T[]
  error: string
}

export const getAll = async <T>(
  url: string,
  sessionToken: string,
  organizationId: string,
): Promise<Response<T>> => {
  try {
    const response = await axios.get<Response<T>>(
      `${API_BASE_URL}/${organizationId}/${url}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${sessionToken}`,
        },
      },
    )
    console.log('response', response)
    if (response.data.result != null) {
      return response.data
    }
    return {
      message: 'No data found',
      result: [],
      error: '',
    }
  } catch (error) {
    console.error('Error fetching data:', error)
    // throw error // Rethrow the error after logging it
    return {
      message: 'Error fetching data',
      result: [],
      error: error as string,
    }
  }
}

export const addItem = async <T>(
  url: string,
  item: T,
  sessionToken: string,
  organizationId: string,
): Promise<any> => {
  return new Promise((res) => {
    res(
      axios
        .post<T>(
          `${API_BASE_URL}/${organizationId}/${url}`,
          { ...item },
          {
            // params: { organizationId },
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + sessionToken,
            },
          },
        )
        .then((response) => {
          return response.data
        })
        .catch((reason): T => {
          if (!navigator.onLine) {
            alert('Please check your internet connection')
          }
          throw reason
        }),
    )
  })
}

export const addOrganization = async <T>(
  url: string,
  item: T,
  // sessionToken: string,
): Promise<any> => {
  //TODO add type
  return new Promise((res) => {
    res(
      axios
        .post<T>(`${API_BASE_URL}/${url}`, item, {
          params: { item },
          headers: {
            Accept: 'application/json',
            // Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data
        })
        .catch((reason): T => {
          if (!navigator.onLine) {
            alert('Please check your internet connection')
          }
          throw reason
        }),
    )
  })
}

export const updateItem = async <T>(
  url: string,
  item: T,
  sessionToken: string,
  organizationId: string,
): Promise<T> => {
  return new Promise((res) => {
    res(
      axios
        .patch<T>(
          `${API_BASE_URL}/${organizationId}/${url}`,
          { ...item },
          {
            params: { id: (item as any).id }, //TODO
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + sessionToken,
            },
          },
        )
        .then((response) => {
          return response.data
        })
        .catch((reason): T => {
          throw reason
        }),
    )
  })
}
export const deleteItem = async <T>(
  url: string,
  id: string,
  sessionToken: string,
  organizationId: string,
): Promise<T> => {
  return new Promise((res) => {
    res(
      axios
        .delete<T>(`${API_BASE_URL}/${organizationId}/${url}`, {
          params: { id },
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + sessionToken,
          },
        })
        .then((response) => {
          return response.data
        })
        .catch((reason): T => {
          if (!navigator.onLine) {
            alert('Please check your internet connection')
          }
          throw reason
        }),
    )
  })
}

export const updateDevice = async <T extends Entity>(
  url: string,
  item: T,
  sessionToken: string,
  organizationId: string,
): Promise<Entity> => {
  return new Promise((res) => {
    res(
      axios
        .patch<T>(
          `${API_BASE_URL}/${organizationId}/${url}/${item.id}`,
          { name: item.name },
          {
            params: { organizationId },
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + sessionToken,
            },
          },
        )
        .then((response) => {
          return response.data
        })
        .catch((reason): T => {
          throw reason
        }),
    )
  })
}
