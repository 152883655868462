import { useState, useEffect, useCallback } from 'react'

export function useDrawingManager(map: google.maps.Map | undefined) {
  const [drawingManager, setDrawingManager] =
    useState<google.maps.drawing.DrawingManager>()
  const [newShape, setNewShape] = useState<
    google.maps.Rectangle | google.maps.Polygon | null
  >(null)

  useEffect(() => {
    if (!drawingManager) {
      const manager = new google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.RECTANGLE,
            google.maps.drawing.OverlayType.POLYGON,
          ],
        },
        rectangleOptions: {
          fillColor: '#ffff00',
          fillOpacity: 0.5,
          strokeWeight: 2,
          clickable: true,
          editable: true,
          zIndex: 1,
        },
        polygonOptions: {
          fillColor: '#ffff00',
          fillOpacity: 0.5,
          strokeWeight: 2,
          clickable: true,
          editable: true,
          zIndex: 1,
        },
      })

      // Add listener for shape completion
      google.maps.event.addListener(
        manager,
        'overlaycomplete',
        (event: google.maps.drawing.OverlayCompleteEvent) => {
          if (newShape) {
            newShape.setMap(null) // Remove the previous shape if exists
          }
          setNewShape(
            event.overlay as google.maps.Rectangle | google.maps.Polygon,
          )
          manager.setDrawingMode(null) // Stop drawing after shape is complete
        },
      )

      setDrawingManager(manager)
    }
  }, [drawingManager, newShape])

  const startDrawing = useCallback(
    (mode: google.maps.drawing.OverlayType) => {
      if (map && drawingManager) {
        drawingManager.setMap(map)
        drawingManager.setDrawingMode(mode)
      }
    },
    [map, drawingManager],
  )

  const stopDrawing = useCallback(() => {
    if (drawingManager) {
      drawingManager.setMap(null)
    }
    if (newShape) {
      newShape.setMap(null)
      setNewShape(null)
    }
  }, [drawingManager, newShape])

  return { drawingManager, newShape, startDrawing, stopDrawing }
}
