import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Container,
  Divider,
  Icon,
  List,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material'
import ShareLocationIcon from '@mui/icons-material/ShareLocation'
import SquareIcon from '@mui/icons-material/Square'
import PentagonIcon from '@mui/icons-material/Pentagon'
import AddIcon from '@mui/icons-material/Add'
import { Geofence } from '../../api/types'
import useWindowSize from '../../customHooks/useWindowSize'
import EditDeletePopMenu from '../EditDeletePopMenu'
import './geostyles.css'
import useClickAway from '../../customHooks/useClickAway'

export type GeofenceListProps = {
  map: google.maps.Map | undefined
  GeoFenceArray: Geofence[]

  handleEdit: (id: string) => void
  deleteGeofence: (id: string) => void
  hideButton: boolean
}

function GeofencesList(props: GeofenceListProps) {
  const windowSize = useWindowSize()
  const [selected, setSelected] = useState('')
  const listContainerRef = useRef(null)
  const [expanded, setExpanded] = useState<string | null>(null)

  useEffect(() => {}, [props.GeoFenceArray.length])

  const handleDeselect = () => {
    setSelected('')
  }

  useClickAway(listContainerRef, handleDeselect)

  const getGeofenceTypeIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case 'rectangle':
        return <SquareIcon fontSize="small" />
      case 'polygon':
        return <PentagonIcon fontSize="small" />
      default:
        return <ShareLocationIcon fontSize="small" />
    }
  }

  return (
    <div ref={listContainerRef}>
      <Stack
        direction={'row'}
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">Geofences</Typography>
      </Stack>
      <Container
        style={{
          width: '100%',
          height:
            windowSize.width > 800
              ? windowSize.height - 180
              : windowSize.height / 2.3,
          overflow: 'auto',
          padding: 0,
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
        }}
      >
        <List sx={{ padding: 0, margin: 0 }}>
          {props.GeoFenceArray.map((value) => (
            <div
              className={`geofenceItem ${
                selected === value.name ? 'active' : ''
              }`}
              key={value.id}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Tooltip title={`Center on ${value.name}`}>
                  <Button
                    style={{ minWidth: 'auto', padding: '6px' }}
                    // onClick={() => {
                    //   if (props.map) {
                    //     const bounds = props.GeoFenceArray.find(
                    //       (item) => item.id === value.id,
                    //     )?.figure.getBounds()
                    //     if (bounds) props.map?.fitBounds(bounds)
                    //     setSelected(value.name)
                    //   }
                    // }}
                  >
                    {getGeofenceTypeIcon(value.type)}
                  </Button>
                </Tooltip>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    flexGrow: 1,
                  }}
                >
                  {value.name}
                </Typography>
                <EditDeletePopMenu
                  confirmationMessage="Are you sure you want to delete this Geofence?"
                  handleEdit={props.handleEdit}
                  handleDelete={props.deleteGeofence}
                  id={value.id!}
                />
              </Stack>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginLeft: 40, marginTop: 4 }}
              >
                Type: {value.type}
              </Typography>

              <Divider style={{ margin: '8px 0' }} />
            </div>
          ))}
        </List>
      </Container>
    </div>
  )
}

export default GeofencesList
