import React, { useState } from 'react'
import NavBarHeader from './NavBarHeader'

import Footer from './Footer'
import { Box } from '@mui/material'
import Hero from './Hero'

const LandingPage = () => {
  const [isScrolled, setIsScrolled] = useState(true)

  // Implement scroll hook if necessary

  return (
    <div
      style={{
        background: 'linear-gradient(90deg, #ffffff 0%, #f0f8ff 100%)',
        flexDirection: 'column',
        display: 'flex',
        minHeight: '100vh',
      }}
    >
      <Hero />
      {/* <Body />  add something else here */}
      <div style={{ flex: 1 }}>
        {' '}
        {/* Add other content here if necessary */}
      </div>
      <Footer />
    </div>
  )
}

export default LandingPage
