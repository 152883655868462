import React, { createContext, useState, useContext, ReactNode } from 'react'

type TopBarContextType = {
  setTopBarContent: (content: ReactNode) => void
  topBarContent: ReactNode
}

const TopBarContext = createContext<TopBarContextType | undefined>(undefined)

export const TopBarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [topBarContent, setTopBarContent] = useState<ReactNode>(null)

  return (
    <TopBarContext.Provider value={{ topBarContent, setTopBarContent }}>
      {children}
      {topBarContent}
    </TopBarContext.Provider>
  )
}

export const useTopBar = () => {
  const context = useContext(TopBarContext)
  if (context === undefined) {
    throw new Error('useTopBar must be used within a TopBarProvider')
  }
  return context
}
