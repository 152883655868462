import { useState, useCallback } from 'react'
import { Geofence, GeofenceWithFigure } from '../../api/types'
import { useAppDispatch } from '../../app/hooks'
import { updGeofence } from '../../redux_features/geofences/geofenceSlice'

export function useGeofenceEditing(
  sessionToken: string,
  organizationId: string,
) {
  const dispatch = useAppDispatch()
  const [editMode, setEditMode] = useState(false)
  const [editIndex, setEditIndex] = useState<number | null>(null)
  const [onEdit, setOnEdit] = useState<GeofenceWithFigure | undefined>()
  const [origBounds, setOrigBounds] = useState<google.maps.LatLngBounds | null>(
    null,
  )
  const [editedCoordinates, setEditedCoordinates] = useState<number[][]>([])

  const findGeofenceById = useCallback(
    (
      geoFenceArray: GeofenceWithFigure[],
      id: string,
    ): GeofenceWithFigure | undefined => {
      return geoFenceArray.find((value: GeofenceWithFigure) => value.id === id)
    },
    [],
  )

  const setupGeofenceForEditing = useCallback(
    (
      geofence: GeofenceWithFigure,
      index: number,
      geoFenceArray: GeofenceWithFigure[],
      defaultColor: google.maps.PolygonOptions | google.maps.RectangleOptions,
      editColor: google.maps.PolygonOptions | google.maps.RectangleOptions,
    ) => {
      setOnEdit({ ...geofence })
      setEditMode(true)
      setEditIndex(index)
      let bounds: google.maps.LatLngBounds
      let originalCoordinates: number[][]
      if (geofence.type === 'Rectangle') {
        const rectangleFigure = geofence.figure as google.maps.Rectangle
        bounds = rectangleFigure.getBounds()!
        const sw = bounds.getSouthWest()
        const ne = bounds.getNorthEast()
        originalCoordinates = [
          [sw.lng(), sw.lat()],
          [ne.lng(), sw.lat()],
          [ne.lng(), ne.lat()],
          [sw.lng(), ne.lat()],
          [sw.lng(), sw.lat()],
        ]
      } else {
        //Assuming Polygon
        const polygonFigure = geofence.figure as google.maps.Polygon
        bounds = new google.maps.LatLngBounds()
        const path = polygonFigure.getPath()
        path.forEach((latLng) => bounds.extend(latLng))
        originalCoordinates = path
          .getArray()
          .map((latLng) => [latLng.lng(), latLng.lat()])
      }
      setOrigBounds(bounds)
      setEditedCoordinates(originalCoordinates)
      geoFenceArray.forEach((element) => {
        element.figure.setOptions(defaultColor as any)
      })
      geofence.figure.setOptions(editColor as any)
      geofence.figure.setDraggable(true)
      geofence.figure.setEditable(true)
    },
    [],
  )

  const cancelEditing = useCallback(() => {
    setEditMode(false)
    setEditIndex(null)
    setOnEdit(undefined)
    setOrigBounds(null)
    setEditedCoordinates([])

    if (onEdit) {
      onEdit.figure.setDraggable(false)
      onEdit.figure.setEditable(false)
      if (onEdit.type === 'Rectangle' && origBounds) {
        ;(onEdit.figure as google.maps.Rectangle).setBounds(origBounds)
      }
      // For Polygon, you might want to reset the path to original coordinates
    }
  }, [onEdit, origBounds])
  const updateGeofence = useCallback(
    async (name: string) => {
      if (onEdit && editedCoordinates.length > 0) {
        const updatedGeofence: Partial<Geofence> = {
          id: onEdit.id,
          name: name,
          type: onEdit.type,
          geometry: {
            type: 'Polygon',
            coordinates: [editedCoordinates],
          },
          // Add any other necessary properties here, but avoid including 'figure'
        }

        console.log(
          'Updating geofence with data:',
          JSON.stringify(updatedGeofence, null, 2),
        )

        try {
          const result = await dispatch(
            updGeofence({
              geofence: updatedGeofence,
              organizationId,
              token: sessionToken,
            }),
          ).unwrap()

          console.log('Update result:', result)

          cancelEditing()
        } catch (err) {
          console.error('Error updating geofence:', err)
          if (err instanceof Error) {
            alert('There was an error updating the geofence: ' + err.message)
          } else {
            alert('There was an unknown error updating the geofence')
          }
        }
      } else {
        console.warn(
          'Cannot update geofence: missing onEdit or editedCoordinates',
        )
      }
    },
    [
      onEdit,
      editedCoordinates,
      dispatch,
      organizationId,
      sessionToken,
      cancelEditing,
    ],
  )

  return {
    editMode,
    editIndex,
    onEdit,
    origBounds,
    editedCoordinates,
    findGeofenceById,
    setupGeofenceForEditing,
    updateGeofence,
    cancelEditing,
    setEditMode,
    setEditedCoordinates,
    setEditIndex,
    setOrigBounds,
    setOnEdit,
  }
}
