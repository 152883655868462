import React from 'react'
import { useWebSocket } from './useWebSocket'
import Notification from './Notification'

const WSocket: React.FC = () => {
  const { notification } = useWebSocket()

  return <>{notification && <Notification message={notification} />}</>
}

export default WSocket
