import React, { useState } from 'react'
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material'
import { addOrganization } from '../../api/api_functions'
import { Organization, User, UserSettings, orgSignUp } from '../../api/types'

import { RootState } from '../../app/store'
import { useSelector } from 'react-redux'
import { setOrganizationId } from './sessionSlice'
import { useAppDispatch } from '../../app/hooks'
import { SelectChangeEvent } from '@mui/material/Select'
import { useNavigate } from 'react-router-dom'
const CompleteRegistration = () => {
  const userEmail = useSelector(
    (state: RootState) => state.session.userdata.email,
  )
  const dispatch = useAppDispatch()

  // User information
  const [userName, setUserName] = useState('')
  const [userPhoneNumber, setUserPhoneNumber] = useState('')

  // Organization information
  const [orgName, setOrganizationName] = useState('')
  const [orgTimezone, setOrgTimezone] = useState('')
  const [orgPhoneNumber, setOrgPhoneNumber] = useState('')
  const [orgAddress, setOrgAddress] = useState('')
  const [orgEmail, setOrgEmail] = useState('')
  const [userSettings, setUserSettings] = useState<UserSettings>({
    timezone: '',
    speedUnit: 'mph',
    distanceUnit: 'mi',
  })
  const navigate = useNavigate()
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const organization: Organization = {
      name: orgName,
      timezone: orgTimezone,
      phoneNumber: orgPhoneNumber,
      address: orgAddress,
      email: orgEmail,
    }

    const user: Partial<User> = {
      name: userName,
      email: userEmail,
      phoneNumber: userPhoneNumber,
    }

    const orgInfo: orgSignUp = {
      organization,
      user: user as User,
      userSettings: { ...userSettings, timezone: orgTimezone },
    }

    addOrganization('organization', orgInfo)
      .then((organizationId) => {
        console.log('Organization created:', organizationId)

        dispatch(setOrganizationId(organizationId))
        navigate('/tracking') // Redirect to dashboard after completion
      })
      .catch((error: any) => {
        console.error('Organization creation failed:', error)
        alert(
          `Organization could not be created: ${
            error.message || 'Unknown error'
          }`,
        )
      })
  }

  const handleUnitChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value
    setUserSettings((prev) => ({
      ...prev,
      speedUnit: value === 'imperial' ? 'mph' : 'kph',
      distanceUnit: value === 'imperial' ? 'mi' : 'km',
    }))
  }
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ mt: 3, maxWidth: 600, margin: 'auto' }}
    >
      <Typography variant="h4" gutterBottom>
        Complete Your Registration
      </Typography>

      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        Personal Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Full Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Phone Number"
            value={userPhoneNumber}
            onChange={(e) => setUserPhoneNumber(e.target.value)}
            required
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Organization Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Organization Name"
            value={orgName}
            onChange={(e) => setOrganizationName(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Time Zone"
            value={orgTimezone}
            onChange={(e) => setOrgTimezone(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Organization Phone Number"
            value={orgPhoneNumber}
            onChange={(e) => setOrgPhoneNumber(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Organization Address"
            value={orgAddress}
            onChange={(e) => setOrgAddress(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Organization Email"
            value={orgEmail}
            onChange={(e) => setOrgEmail(e.target.value)}
            required
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        User Settings
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="units-label">Units</InputLabel>
            <Select
              labelId="units-label"
              name="units"
              value={userSettings.speedUnit === 'mph' ? 'imperial' : 'metric'}
              label="Units"
              onChange={handleUnitChange}
            >
              <MenuItem value="imperial">Miles / Miles per hour</MenuItem>
              <MenuItem value="metric">
                Kilometers / Kilometers per hour
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Complete Registration
      </Button>
    </Box>
  )
}

export default CompleteRegistration
