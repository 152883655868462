import React, { useCallback } from 'react'
import './BottomTabs.css'
import { useNavigate, useLocation } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MapIcon from '@mui/icons-material/Map'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import AssessmentIcon from '@mui/icons-material/Assessment'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

const mobileMenuItems = [
  { name: 'Dashboard', to: '/dashboard', icon: DashboardIcon },
  { name: 'Track', to: '/live-tracking', icon: MapIcon },
  { name: 'Assets', to: '/assets', icon: LocalShippingIcon },
  { name: 'Reports', to: '/reports', icon: AssessmentIcon },
  { name: 'More', to: '/more', icon: MoreHorizIcon },
]

export default function BottomTabs() {
  const navigate = useNavigate()
  const location = useLocation()

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      navigate(newValue)
    },
    [navigate],
  )

  return (
    <BottomNavigation
      value={location.pathname}
      onChange={handleChange}
      className="BottomTabs--MainContainer"
    >
      {mobileMenuItems.map((item) => (
        <BottomNavigationAction
          key={item.name}
          label={item.name}
          value={item.to}
          icon={<item.icon />}
        />
      ))}
    </BottomNavigation>
  )
}
