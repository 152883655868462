import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material'

const mockReports = [
  {
    id: 1,
    name: 'Daily Vehicle Usage',
    date: '2023-04-15',
    status: 'Completed',
  },
  {
    id: 2,
    name: 'Weekly Driver Performance',
    date: '2023-04-14',
    status: 'In Progress',
  },
  {
    id: 3,
    name: 'Monthly Fuel Efficiency',
    date: '2023-04-01',
    status: 'Scheduled',
  },
  {
    id: 4,
    name: 'Quarterly Maintenance Summary',
    date: '2023-03-31',
    status: 'Completed',
  },
]

function Reports() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Reports
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Report Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mockReports.map((report) => (
              <TableRow key={report.id}>
                <TableCell>{report.name}</TableCell>
                <TableCell>{report.date}</TableCell>
                <TableCell>{report.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Reports
