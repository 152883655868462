import { useEffect, useRef, useState } from 'react'
import { MapProps } from '../../api/types'

import MapController2 from './MapController2'

// Create a promise to load the Google Maps API
let googleMapsPromise: Promise<void> | null = null

const loadGoogleMapsAPI = () => {
  if (!googleMapsPromise) {
    googleMapsPromise = new Promise<void>((resolve) => {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places,drawing`
      script.async = true
      script.onload = () => resolve()
      document.head.appendChild(script)
    })
  }
  return googleMapsPromise
}

const MapJS = (props: MapProps) => {
  const refmap = useRef<HTMLDivElement | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)

  useEffect(() => {
    loadGoogleMapsAPI().then(() => {
      if (refmap.current && !map) {
        // Initialize the map
        const newMap = new window.google.maps.Map(refmap.current, {
          center: props.center,
          zoom: props.zoom,
        })
        setMap(newMap) // Save map to state
      }
    })
  }, [refmap, map, props.center, props.zoom])

  return (
    <div className={`${props.enable_controller ? 'track-grid-container' : ''}`}>
      <div id="list-wrap">
        {props.enable_controller && map && (
          <MapController2 {...props} map={map} />
        )}
      </div>
      <div
        id="map"
        style={{
          height: props.height,
        }}
        ref={refmap}
      >
        map
      </div>
    </div>
  )
}

export default MapJS
