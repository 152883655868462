import React from 'react'
import { Grid, Box, Typography } from '@mui/material'
import { WidgetMap } from './DashboardWidgets'
// import { selectUserDashboardPreferences } from '../redux_features/user/userSlice'
type WidgetId = keyof typeof WidgetMap
// Hardcoded user preferences
const userPreferences: { widgets: WidgetId[] } = {
  widgets: [
    'activeDrivers',
    'totalVehicles',
    'averageSpeed',
    'activeRoutes',
    // 'vehicleUtilization',
    // 'vehicleStatus',
  ],
}

const Dashboard = () => {
  // const userPreferences = useSelector(selectUserDashboardPreferences)

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Fleet Dashboard
      </Typography>
      <Grid container spacing={3}>
        {userPreferences.widgets.slice(0, 4).map((widgetId) => {
          const Widget = WidgetMap[widgetId]
          return (
            <Grid item xs={12} sm={6} md={3} key={widgetId}>
              <Widget />
            </Grid>
          )
        })}
        <Grid item xs={12} md={8}>
          {/* {WidgetMap['vehicleUtilization']()} */}
        </Grid>
        <Grid item xs={12} md={4}>
          {/* {WidgetMap['vehicleStatus']()} */}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashboard
