//import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {} from './sessionSlice'

import SessionLogin from './SessionLogin'
import { RootState } from '../../app/store'
import { useEffect } from 'react'

type ProtectedRouteType = {
  roleRequired: 'admin' | 'driver'
  drawerStatus: Boolean
}

// const userParsedData: UserData[] = JSON.parse(usersInfo)

const RequireCogAuth = (props: ProtectedRouteType) => {
  const sessionStatus = useSelector((state: RootState) => state.session.status)

  useEffect(() => {
    console.log(props)
  })

  let content
  if (sessionStatus === 'failed' || sessionStatus === 'idle')
    content = <SessionLogin compactView={true} />
  else {
    content = (
      // this is to render the grid layout only when is logged in, please do not remove unless thoroughly tested
      // <div className={`contenedor ${!props.drawerStatus ? 'inactive' : ''}`}>
      <div>
        {' '}
        <Outlet />
      </div>
    )
  } //else

  return <>{content}</>
}
export default RequireCogAuth
