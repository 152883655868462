import React from 'react'

import {
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  useTheme,
} from '@mui/material'

export const StatCard = ({
  icon,
  title,
  value,
  color,
}: {
  icon: React.ReactElement
  title: string
  value: string
  color: string
}) => (
  <Card>
    <CardContent>
      <Box display="flex" alignItems="center">
        {React.cloneElement(icon, { style: { fontSize: 40, color } })}
        <Box ml={2}>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="h4" component="div">
            {value}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
)
