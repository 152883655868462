import { LastKnownData } from '../api/types'

export const calculateMapCenterAndZoom = (
  livegeolocations: LastKnownData[],
) => {
  const coordinates: { lat: number; lng: number }[] = []
  console.log('lenght=', coordinates.length)

  livegeolocations.forEach((element) => {
    const lat = element.latitude
    const lng = element.longitude
    coordinates.push({ lat, lng })
  })

  if (!Array.isArray(coordinates)) {
    // Return default center and zoom if no coordinates are provided
    return { center: { lat: 0, lng: 0 }, zoom: 10 }
  }
  if (coordinates.length > 0) {
    // Initialize min and max values for latitude and longitude
    let minLat = coordinates[0].lat
    let maxLat = coordinates[0].lat
    let minLng = coordinates[0].lng
    let maxLng = coordinates[0].lng

    // Find the min and max values for latitude and longitude
    coordinates.forEach((coord) => {
      minLat = Math.min(minLat, coord.lat)
      maxLat = Math.max(maxLat, coord.lat)
      minLng = Math.min(minLng, coord.lng)
      maxLng = Math.max(maxLng, coord.lng)
    })

    // Calculate the center latitude and longitude using the average values
    const centerLat = (maxLat + minLat) / 2
    const centerLng = (maxLng + minLng) / 2

    // Calculate the distance between the center and the farthest coordinate
    const latRadian = (maxLat - minLat) * (Math.PI / 180)
    const lngRadian = (maxLng - minLng) * (Math.PI / 180)
    const distanceLat = 111.32 * latRadian // 1 degree of latitude is approximately 111.32 kilometers
    const distanceLng =
      111.32 * Math.cos(centerLat * (Math.PI / 180)) * lngRadian
    const maxDistance = Math.max(distanceLat, distanceLng)

    // Calculate the zoom level based on the distance

    const zoomFactor = 0.2 // Adjust this value to control how close the zoom should be (0.1 to 1, where 1 is no zoom reduction)
    const adjustedMaxDistance = maxDistance * zoomFactor

    //const zoom = Math.floor(Math.log2(360 / maxDistance))
    const zoom = Math.floor(Math.log2(360 / adjustedMaxDistance))

    // Return the calculated center and zoom level

    return { center: { lat: centerLat, lng: centerLng }, zoom }
  } else return { center: { lat: 0, lng: 0 }, zoom: 10 }
}
