import React from 'react'
import { Typography, Grid } from '@mui/material'
import ActiveRoutesWidget from './ActiveRoutesWidget'
import RouteMap from './RouteMap'
import DispatchingQueue from './DispatchingQueue'

const Dispatching: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Dispatching</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <ActiveRoutesWidget />
      </Grid>
      <Grid item xs={12} md={8}>
        <RouteMap />
      </Grid>
      <Grid item xs={12}>
        <DispatchingQueue />
      </Grid>
    </Grid>
  )
}

export default Dispatching
