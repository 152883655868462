// export const menuItems = [
//   // {
//   //   name: 'Dashboard',
//   //   exact: true,
//   //   to: '/dashboard',
//   //   iconClassName: 'dashboard',
//   // },
//   {
//     name: 'Tracking',
//     exact: true,
//     to: '/tracking',
//     iconClassName: 'mode_of_travel',
//   },

//   {
//     name: 'Vehicles',
//     exact: true,
//     to: `/settings/Vehicles`,
//     iconClassName: 'local_shipping',
//     subMenus: [],
//   },
//   {
//     name: 'Drivers',
//     exact: true,
//     to: `/settings/drivers`,
//     iconClassName: 'person',
//     subMenus: [],
//   },
//   {
//     name: 'Devices',
//     exact: true,
//     to: `/settings/devices`,
//     iconClassName: 'sensors',
//     subMenus: [],
//   },
//   {
//     name: 'All Assets',
//     exact: true,
//     to: `/settings`,
//     iconClassName: 'settings',
//     subMenus: [],
//   },
//   // {
//   //   name: 'Geofence',
//   //   exact: true,
//   //   to: `/geofences`,
//   //   iconClassName: 'bi bi-geo-fill',
//   //   subMenus: [],
//   // },
// ]


export const menuItems = [
  {
    name: 'Dashboard',
    exact: true,
    to: '/dashboard',
    iconClassName: 'dashboard',
  },
  {
    name: 'Live Tracking',
    exact: true,
    to: '/live-tracking',
    iconClassName: 'location_on',
  },
  {
    name: 'Reports',
    exact: false,
    to: '/reports',
    iconClassName: 'assessment',
    subMenus: [
      { name: 'Trip Reports', to: '/reports/trips' },
      { name: 'Driver Performance', to: '/reports/driver-performance' },
      { name: 'Fuel Consumption', to: '/reports/fuel-consumption' },
    ],
  },
  {
    name: 'Assets',
    exact: false,
    to: '/assets',
    iconClassName: 'local_shipping',
    subMenus: [
      { name: 'Vehicles', to: '/assets/vehicles' },
      { name: 'Drivers', to: '/assets/drivers' },
      { name: 'Devices', to: '/assets/devices' },
    ],
  },
  {
    name: 'Geofences',
    exact: true,
    to: '/geofences',
    iconClassName: 'fence',
  },
  {
    name: 'Alerts',
    exact: true,
    to: '/alerts',
    iconClassName: 'notifications',
  },
  {
    name: 'Settings',
    exact: true,
    to: '/settings',
    iconClassName: 'settings',
  },
]