import React from 'react'
import { LastKnownData } from '../../api/types'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import VehiclesControllersDev from '../VehiclesController_dev'

interface VehiclesTabProps {
  onCardClick: (item: LastKnownData) => void
  onHistoryClick: (deviceId: string) => void
  onInfoClick: (deviceId: string) => void
  selectedTab: string
}

const VehiclesTab: React.FC<VehiclesTabProps> = ({
  onCardClick,
  onHistoryClick,
  onInfoClick,
  selectedTab,
}) => {
  const vehicleStatus = useSelector((state: RootState) => state.vehicles.status)
  const geolocationStatus = useSelector(
    (state: RootState) => state.geolocations.status,
  )

  return (
    <div className="vehicles-tab">
      <VehiclesControllersDev
        onclickHandler={onCardClick}
        historyClick={onHistoryClick}
        infoClick={onInfoClick}
        selectedTab={selectedTab}
      />
    </div>
  )
}

export default VehiclesTab
