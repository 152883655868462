import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { addItem, getAll, updateItem } from '../../api/api_functions'
import { Vehicle, secureVehicle, secureCompany } from '../../api/types'

let vehicles: Partial<Vehicle>[] = []

let initialState = {
  vehicles,
  status: 'idle',
  error: '',
}

export const fetchVehicles = createAsyncThunk(
  'vehicles/fetchVehicles',
  async (obj: secureCompany) => {
    const response = await getAll<Vehicle>(
      'vehicles',
      obj.token,
      obj.organizationId,
    )

    return response
  },
)

export const addNewVehicle = createAsyncThunk(
  'vehicles/addNewVehicle',
  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureVehicle) => {
    const response = await addItem(
      'vehicles',
      obj.vehicle,
      obj.token,
      obj.organizationId,
    )
    // The response includes the complete post object, including unique ID
    obj.vehicle.id = response['result']['id']

    return obj.vehicle // need to reutn the vehcile to add to the redux  store
  },
)
export const vehicleUpdated = createAsyncThunk(
  'vehicles/vehicleUpdated',

  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureVehicle) => {
    // We send the initial data to the fake API server
    const response = await updateItem(
      'vehicles',
      obj.vehicle,
      obj.token,
      obj.organizationId,
    )

    // The response includes the complete post object, including unique ID
    return obj.vehicle // need to reuturn the vehcile to add to the redux  store
  },
)
// maybe change status D here and use same update
export const deleteVehicle = createAsyncThunk(
  'vehicle/deleteVehicle',
  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureVehicle) => {
    const response = await updateItem(
      'vehicle',
      obj.vehicle,
      obj.token,
      obj.organizationId,
    )
    console.log(response)
    // The response includes the complete post object, including unique ID
    return obj.vehicle.id // need to reutn the user to add to the redux  store
  },
)

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchVehicles.pending, (state, action: PayloadAction) => {
        state.status = 'loading'
      })
      .addCase(fetchVehicles.fulfilled, (state, action) => {
        state.status = 'succeeded'

        // Add any fetched posts to the array

        state.vehicles = state.vehicles.concat(action.payload.result)
      })
      .addCase(fetchVehicles.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'Vehicles could not be retrieved'
        //TODO  state.error = action.error.message
        //
      })
      .addCase(addNewVehicle.fulfilled, (state, action) => {
        // We can directly add the new post object to our posts array ....always remember that inside create slice inmutability is respected due tu intrinsic use of immer

        state.vehicles.push(action.payload)
        alert('New Vehicle added sucessfully')
      })

      .addCase(vehicleUpdated.fulfilled, (state, action) => {
        const updatedVehicle: Partial<Vehicle> = action.payload

        const existingVehicle = state.vehicles.find(
          (vehic: Partial<Vehicle>) => vehic.id === updatedVehicle.id,
        )
        //add here all the features to be updated from vehicle, device id
        if (existingVehicle) {
          //TODO assignation has to be item by item for some reason I did not investigate...do not chnage without testing

          existingVehicle.name = updatedVehicle.name
          existingVehicle.description = updatedVehicle.description
          existingVehicle.driver = updatedVehicle.driver
          existingVehicle.device = updatedVehicle.device
          existingVehicle.pathColor = updatedVehicle.pathColor
        }
        alert('Vehicle updated sucessfully')
      })
      .addCase(vehicleUpdated.rejected, (state, action) => {
        // We can directly add the new post object to our posts array ....always remember that inside create slice inmutability is respected due tu intrinsic use of immer
        alert('Vehicle could not be updated, please try again later')
      })
      .addCase(deleteVehicle.fulfilled, (state, action) => {
        const deletedVehicleId = action.payload
        state.vehicles = state.vehicles.filter(
          (vehicle: Partial<Vehicle>) => vehicle.id !== deletedVehicleId,
        )
        alert('Vehicle deleted successfully')
      })
      .addCase(deleteVehicle.rejected, (state, action) => {
        alert('Vehicle could not be deleted, try again later')
      })
  },
})

export default vehiclesSlice.reducer

export const selectAllVehicles = (state: {
  vehicles: { vehicles: Vehicle[] }
}) => state.vehicles.vehicles
