import React, { useRef, useState } from 'react'
import { AppBar, Toolbar, Typography, IconButton, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import UserProfileMenu from '../user/UserProfileMenu'
import UserNotifications from '../user/UserNotifications'
import logo from '../../assets/Company_Logo2.png'
import { useTopBar } from './TopBarContext'
import useIsMobile from '../../customHooks/useIsMobile'
import useClickAway from '../../customHooks/useClickAway'
import { useNavigate } from 'react-router-dom'

const drawerWidth = 160 // review this value
const compactDrawerWidth = 66 // review this value , make it more adaptive
const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isLoggedIn',
})<{ open?: boolean; isLoggedIn?: boolean }>(({ theme, open, isLoggedIn }) => {
  const effectiveCompactDrawerWidth = isLoggedIn ? compactDrawerWidth : 0

  return {
    zIndex: theme.zIndex.drawer + 1,
    background: 'rgba(255, 255, 255, 0.8)', // Slight transparency
    backdropFilter: 'blur(10px)', // Glassmorphism effect
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12)', // Subtle shadow
    borderBottom: '1px solid rgba(0, 0, 0, 0.06)', // Thin border
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: open
      ? `calc(100% - ${drawerWidth}px)`
      : `calc(100% - ${effectiveCompactDrawerWidth}px)`,
    marginLeft: open ? `${drawerWidth}px` : `${effectiveCompactDrawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }
})

interface TopBarProps {
  isLoggedIn: boolean
  onMenuToggle: () => void
  open: boolean
  pageSpecificContent?: React.ReactNode
}

interface TopBarProps {
  isLoggedIn: boolean
  onMenuToggle: () => void
  pageSpecificContent?: React.ReactNode
}

const LogoContainer = styled('div')({
  marginRight: '32px',
  display: 'flex',
  alignItems: 'center',
})

const TopBar: React.FC<TopBarProps> = ({
  isLoggedIn,
  onMenuToggle,
  open,
  pageSpecificContent,
}) => {
  const [anchorLogin, setAnchorLogin] = useState(null)
  const [anchorBurguer, setAnchorBurguer] = useState(null)
  const dropdownRef = useRef(null)
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { topBarContent } = useTopBar()
  const handleClickLogin = (event: any) => {
    setAnchorLogin(event.currentTarget)
  }

  const handleClickBurguer = (event: any) => {
    setAnchorBurguer(event.currentTarget)
  }

  const handleCloseLogin = () => {
    setAnchorLogin(null)
  }
  const handleCloseBurguer = () => {
    setAnchorBurguer(null)
  }

  const handleCloseAll = () => {
    handleCloseBurguer()
    handleCloseLogin()
  }
  const handleShowMenu = () => {}
  useClickAway(dropdownRef, handleCloseAll)

  return (
    <StyledAppBar
      position="fixed"
      elevation={0}
      open={open}
      isLoggedIn={isLoggedIn}
    >
      <Toolbar>
        <LogoContainer>
          <a href="https://vermmis.com">
            <img src={logo} width="56" height="32" alt="Logo" />
          </a>
        </LogoContainer>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onMenuToggle}
        >
          {/* Add menu icon here */}
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {/* App title or other content */}
          {topBarContent}
        </Typography>
        {pageSpecificContent}

        {isLoggedIn ? (
          <>
            <UserNotifications status={true} />
            <UserProfileMenu status={true} />
          </>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate('login', { replace: true })
            }}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </StyledAppBar>
  )
}

export default TopBar
