import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { Vehicle } from '../../api/types'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

export function useMapLiveMarkers(map: google.maps.Map | undefined) {
  const livegeolocations = useSelector(
    (state: RootState) => state.geolocations.geolocations,
  )
  const geolocationStatus = useSelector(
    (state: RootState) => state.geolocations.status,
  )
  const vehicles = useSelector((state: RootState) => state.vehicles.vehicles)

  const markerRefs = useRef<Record<string, google.maps.Marker>>({})
  const markerClustererRef = useRef<MarkerClusterer | null>(null)

  const animateMarker = (
    marker: google.maps.Marker,
    newPosition: google.maps.LatLng,
    newHeading: number,
  ) => {
    const startPosition = marker.getPosition()
    let deltaLat = 0
    let deltaLng = 0
    let step = 0

    if (startPosition) {
      deltaLat = (newPosition.lat() - startPosition.lat()) / 100
      deltaLng = (newPosition.lng() - startPosition.lng()) / 100

      const moveMarker = () => {
        const lat = startPosition.lat() + deltaLat * step
        const lng = startPosition.lng() + deltaLng * step
        marker.setPosition(new google.maps.LatLng(lat, lng))
        if (step < 100) {
          step++
          requestAnimationFrame(moveMarker)
        } else {
          // Update rotation after movement is complete
          const icon = marker.getIcon() as google.maps.Symbol
          icon.rotation = newHeading
          marker.setIcon(icon)
        }
      }

      moveMarker()
    }
  }

  useEffect(() => {
    if (!map || geolocationStatus !== 'succeeded') {
      console.log('Map not ready or geolocation not succeeded yet')
      return
    }

    const updatedMarkers: google.maps.Marker[] = []

    livegeolocations.forEach((geo) => {
      let marker = markerRefs.current[geo.deviceId]
      const color = vehicles.find(
        (vehic: Partial<Vehicle>) => vehic?.device?.id === geo.deviceId,
      )?.pathColor

      if (!marker) {
        console.log('Creating new marker')
        const markerSymbol: google.maps.Symbol = {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          rotation:
            typeof geo.heading === 'string'
              ? parseInt(geo.heading)
              : geo.heading,
          scale: 5,
          fillColor: color || 'black',
          strokeColor: color || 'black',
          strokeWeight: 3,
        }
        marker = new google.maps.Marker({
          position: new google.maps.LatLng(geo.latitude, geo.longitude),
          map: map,
          icon: markerSymbol,
        })

        const infowindow = new google.maps.InfoWindow({
          content:
            vehicles.find(
              (vehic: Partial<Vehicle>) => vehic?.id === geo.vehicleId,
            )?.name || 'No name',
          ariaLabel: 'Uluru',
        })

        marker.addListener('click', () => {
          infowindow.open(map, marker)
        })

        markerRefs.current[geo.deviceId] = marker
      } else {
        console.log('Updating existing marker')
        const newPosition = new google.maps.LatLng(geo.latitude, geo.longitude)
        const newHeading =
          typeof geo.heading === 'string' ? parseInt(geo.heading) : geo.heading
        animateMarker(marker, newPosition, newHeading)
      }

      updatedMarkers.push(marker)
    })

    // Remove markers that are no longer in the livegeolocations data
    Object.keys(markerRefs.current).forEach((deviceId) => {
      if (!livegeolocations.some((geo) => geo.deviceId === deviceId)) {
        markerRefs.current[deviceId].setMap(null)
        delete markerRefs.current[deviceId]
      }
    })

    // Update the MarkerClusterer with the current set of markers
    if (markerClustererRef.current) {
      markerClustererRef.current.clearMarkers()
      markerClustererRef.current.addMarkers(updatedMarkers)
    } else {
      markerClustererRef.current = new MarkerClusterer({
        markers: updatedMarkers,
        map: map,
      })
    }
  }, [livegeolocations, vehicles, map])

  // Return any values or functions if needed
  return null
}
