import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import VehiclesTab from './VehiclesTab'
import GeofencesTab from './GeofencesTab'
import RightDrawerContent from './RightDrawerContent'
import { MapControllerViewProps } from './mapTypes'

const DesktopView: React.FC<MapControllerViewProps> = ({
  state,
  handlers,
  mapProps,
}) => {
  return (
    <div>
      <Tabs
        activeKey={state.selectedTab}
        onSelect={(eventKey) => eventKey && handlers.setSelectedTab(eventKey)}
      >
        <Tab eventKey="Vehicles" title="Vehicles">
          <VehiclesTab
            onCardClick={handlers.handleCardClick}
            onHistoryClick={handlers.historyClick}
            onInfoClick={handlers.infoClick}
            selectedTab={state.selectedTab}
          />
        </Tab>
        <Tab eventKey="Geofences" title="Geofences">
          <GeofencesTab map={mapProps.map} selectedTab={state.selectedTab} />
        </Tab>
      </Tabs>
      <RightDrawerContent
        state={state}
        handlers={handlers}
        mapProps={mapProps}
      />
    </div>
  )
}

export default DesktopView
