import React from 'react'
import CustomSwipeableDrawer from '../CustomSwipeableDrawer/CustomSwipeableDrawer'
import VehiclesTab from './VehiclesTab'
import RightDrawerContent from './RightDrawerContent'
import { MapControllerViewProps } from './mapTypes'

const MobileView: React.FC<MapControllerViewProps> = ({
  state,
  handlers,
  mapProps,
}) => {
  return (
    <>
      <CustomSwipeableDrawer
        content={
          <VehiclesTab
            onCardClick={handlers.handleCardClick}
            onHistoryClick={handlers.historyClick}
            onInfoClick={handlers.infoClick}
            selectedTab={state.selectedTab}
          />
        }
      />
      <RightDrawerContent
        state={state}
        handlers={handlers}
        mapProps={mapProps}
      />
    </>
  )
}

export default MobileView
