import React from 'react'
import GeofencesController from '../geofences/GeofencesController'

interface GeofencesTabProps {
  map: google.maps.Map | undefined
  selectedTab: string
}

const GeofencesTab: React.FC<GeofencesTabProps> = ({ map, selectedTab }) => {
  // Implement the GeofencesTab component
  return (
    <div>
      <GeofencesController map={map} selectedTab={selectedTab} />
    </div>
  )
}

export default GeofencesTab
