import {
  CognitoUser,
  CognitoUserSession,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js'
import Pool from '../../api/cognito/UserPool'

//TODO review Promise<unknown> and tipificar todo esto
export type AuthError = {
  code:
    | 'auth_failed'
    | 'missing_organization'
    | 'changepass'
    | 'unknown_error'
    | 'new_password'
  message: string
}

export interface AuthResult {
  success: boolean
  session?: CognitoUserSession
  error?: AuthError
  userAttributes?: Record<string, string>
  user?: CognitoUser
}

export const authenticate = async (
  Username: string,
  Password: string,
): Promise<AuthResult> => {
  return new Promise((resolve) => {
    const user = new CognitoUser({ Username, Pool })
    const authDetails = new AuthenticationDetails({ Username, Password })

    user.authenticateUser(authDetails, {
      onSuccess: (session: CognitoUserSession) => {
        console.log('session', session)
        resolve({ success: true, session })
      },
      onFailure: (err) => {
        console.log('error', err)
        let error: AuthError = {
          code: 'unknown_error',
          message: 'An unknown error occurred',
        }
        if (
          err.name === 'NotAuthorizedException' &&
          err.message === 'Incorrect username or password.'
        ) {
          error = {
            code: 'auth_failed',
            message: 'Incorrect username or password',
          }
        } else if (
          err.name === 'UserLambdaValidationException' &&
          err.message.includes(
            "Cannot read properties of undefined (reading 'organization')",
          )
        ) {
          error = {
            code: 'missing_organization',
            message: 'Organization information is missing',
          }
        }
        resolve({ success: false, error, user })
      },
      newPasswordRequired: (userAttributes) => {
        delete userAttributes.email_verified
        delete userAttributes.email
        resolve({
          success: false,
          error: {
            code: 'new_password',
            message: 'A new password is required',
          },
          userAttributes,
          user,
        })
      },
    })
  })
}

export const getSession = async () => {
  return await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser()
    console.log('current user', user)
    if (user) {
      user.getSession(async (err: any, session: CognitoUserSession) => {
        if (err) {
          reject()
        } else {
          const attributes: CognitoUserAttribute = await new Promise(
            (resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err)
                } else {
                  const results: any = {}

                  //TODO review
                  if (attributes)
                    for (let attribute of attributes) {
                      const { Name, Value } = attribute

                      results[Name] = Value
                    }

                  resolve(results)
                }
              })
            },
          )
          resolve({ user, session, attributes })
        }
      })
    } else {
      reject()
    }
  })
}

export const userLogout = () => {
  const user = Pool.getCurrentUser()
  if (user) {
    user.signOut()
    //review why clear works and remove item doesnt, maybe wrong item name?
    localStorage.clear()
    localStorage.removeItem('persist:root')
  }
}

export const confirmRegistration = async (Username: string, code: string) => {
  return await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool })

    const callback = (err: any, result: any) => {
      if (err) {
        reject(err)
        return
      }
      resolve(result)
    }

    //TODO review forceAliasCreation true vs false
    user.confirmRegistration(code, false, callback)
  })
}

export async function signUpUser(
  username: string,
  password: string,
  email: string,
): Promise<string> {
  const attributeList: CognitoUserAttribute[] = []
  const emailAttribute = {
    Name: 'email',
    Value: email,
  }
  attributeList.push(new CognitoUserAttribute(emailAttribute))
  return new Promise<string>((resolve, reject) => {
    Pool.signUp(username, password, attributeList, [], (err, result) => {
      if (err) {
        reject(err.message || 'Error signing up the user')
      } else {
        if (result) {
          const cognitoUser: CognitoUser = result.user
          resolve(cognitoUser.getUsername())
        }
      }
    })
  })
}

// WIP
export const testrefreshtoken = async () => {
  return await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser()

    if (user) {
      user.getSession(async (err: any, session: CognitoUserSession) => {
        if (err) {
          reject()
        } else {
          if (!session.isValid) {
            console.log(
              user.refreshSession(session.getRefreshToken(), (err, session) => {
                console.log('refreshed session')
                console.log(session)
              }),
            )
          }

          resolve({ user, session })
        }
      })
    } else {
      reject()
    }
  })
}

export const vermmisSessionRefresh = async (
  // this is supposed to be  called  to see if ito refresh the token is needed

  tokenexpDate: number,
): Promise<CognitoUserSession | null> => {
  const currentTimestamp = Math.floor(Date.now() / 1000)
  console.log(currentTimestamp)

  if (tokenexpDate < currentTimestamp) {
    try {
      const data: any = await getSession() // Assuming getSession returns a promise
      const session: CognitoUserSession = data.session
      const user: CognitoUser = data.user

      const refreshToken = session.getRefreshToken()

      return new Promise((resolve, reject) => {
        user.refreshSession(refreshToken, (err, refreshedSession) => {
          if (err) {
            console.error('Token refresh failed:', err)
            reject(err)
          } else {
            console.log('Token refresh successful')
            resolve(refreshedSession)
          }
        })
      })
    } catch (error) {
      console.error('Error getting session:', error)
      return null
    }
  } else {
    return null
  }
}

export const createUser = (
  //#TODO  USE api endpoint
  email: string,
  password: string,
  pool: CognitoUserPool,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const attributeList: CognitoUserAttribute[] = []
    const emailAttribute = new CognitoUserAttribute({
      Name: 'email',
      Value: email, // The email variable should hold the user's email address
    })

    attributeList.push(emailAttribute)

    pool.signUp(
      email,
      password,
      attributeList,
      [], // Fix: Pass an empty array instead of null
      (err: any, result: any) => {
        if (err) {
          console.error(err.message || JSON.stringify(err))
          reject(err) // Reject the promise on error
        } else {
          console.log(result)
          resolve(result.user) // Resolve the promise with the user
        }
      },
    )
  })
}

export const confirmUser = (
  username: string,
  confirmationCode: string,
  pool: CognitoUserPool,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: username,
      Pool: pool,
    }

    const cognitoUser = new CognitoUser(userData)

    cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        console.error(err.message || JSON.stringify(err))
        reject(err) // Reject the promise on error
      } else {
        console.log('Confirmation result: ' + result)
        resolve(cognitoUser) // Resolve the promise with the cognitoUser
      }
    })
  })
}

export const requestCodeResend = async (
  username: string,
  pool: CognitoUserPool,
) => {
  return new Promise((resolve, reject) => {
    const userData = {
      Username: username,
      Pool: pool,
    }

    const cognitoUser = new CognitoUser(userData)

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        console.error(err.message || JSON.stringify(err))
        reject(err) // Reject the promise on error
      } else {
        console.log('Confirmation code resent')
        resolve(cognitoUser) // Resolve the promise with the cognitoUser
      }
    })
  })
}


