import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit'
import {
  Geofence,
  GeofenceWithFigure,
  secureCompany,
  secureGeofence,
} from '../../api/types'

import {
  addItem,
  deleteItem,
  getAll,
  updateItem,
} from '../../api/api_functions'

import { createFigureForGeofence } from './geofenceSelectors'
interface GeofenceState {
  geofences: Geofence[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string
}

// Update the initial state
const initialState: GeofenceState = {
  geofences: [],
  status: 'idle',
  error: '',
}
export const fetchGeofences = createAsyncThunk(
  'geofences/fetchGeofences',

  async (obj: secureCompany) => {
    const response = await getAll<Geofence>(
      'geofences',
      obj.token,
      obj.organizationId,
    )
    return response
  },
)

export const createGeofence = createAsyncThunk(
  'geofences/createGeofence',

  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureGeofence) => {
    // We send the initial data to the fake API server
    const response = await addItem(
      'geofences',
      obj.geofence,
      obj.token,
      obj.organizationId,
    )
    obj.geofence.id = response['result']
    // The response includes the complete post object, including unique ID
    return obj.geofence // Return the response to add to the redux store
  },
)

export const updGeofence = createAsyncThunk(
  'geofences/geofenceUpdated',

  // The payload creator receives the partial `{title, content, user}` object
  async (obj: secureGeofence) => {
    // We send the initial
    console.log('obj', obj.geofence)
    const response = await updateItem<any>(
      'geofences',
      obj.geofence as Geofence,
      obj.token,
      obj.organizationId,
    )

    console.log('response', response)
    return obj.geofence //
  },
)
export const deleteGeofence = createAsyncThunk(
  'geofences/deleteGeofence',
  async (obj: secureGeofence) => {
    const response = await deleteItem<Geofence>(
      'geofences',
      obj.geofence.id as string,
      obj.token,
      obj.organizationId,
    )
    return obj.geofence.id
  },
)

const geofenceSlice = createSlice({
  name: 'geofences',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGeofences.pending, (state, action: PayloadAction) => {
        state.status = 'loading'
      })
      .addCase(fetchGeofences.fulfilled, (state, action) => {
        state.geofences = action.payload.result
        state.status = 'succeeded'
      })
      .addCase(fetchGeofences.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'Geofences list could not be retrieved'
        // state.vehicles.error = action.error.message
        //
      })
      .addCase(updGeofence.fulfilled, (state, action) => {
        const updatedGeofence: Partial<Geofence> = action.payload

        const exitingGeofence = state.geofences.find(
          (dev: Partial<Geofence>) => dev.id === updatedGeofence.id,
        )
        if (exitingGeofence) {
          Object.assign(exitingGeofence, updatedGeofence)
        }
        alert('Geofence updated sucessfully')
      })
      .addCase(updGeofence.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'Geofence could not be updated'
        // state.vehicles.error = action.error.message
        //
      })
      // ... (keep existing cases)
      .addCase(deleteGeofence.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteGeofence.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.geofences = state.geofences.filter(
          (geofence: Partial<Geofence>) => geofence.id !== action.payload,
        )
      })
      .addCase(deleteGeofence.rejected, (state, action) => {
        state.status = 'failed'
        state.error = 'Geofence could not be deleted'
      })
      .addCase(createGeofence.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.geofences.push(action.payload as Geofence)
        alert('Geofence created sucessfully')
      })
      .addCase(createGeofence.rejected, (state) => {
        state.status = 'failed'
        state.error = 'Geofence could not be created'
      })
  },
})

// export const selectAllGeofences = (state: {  geofences: { geofences: Geofence[] }}) => state.geofences.geofences
export const selectAllGeofences = (state: { geofences: GeofenceState }) =>
  state.geofences.geofences

export const selectAllGeofencesWithFigures = createSelector(
  [selectAllGeofences],
  (geofences): GeofenceWithFigure[] => {
    return geofences.map((geofence) => ({
      ...geofence,
      figure: createFigureForGeofence(geofence),
    }))
  },
)

export default geofenceSlice.reducer
