import { Geofence } from '../../api/types'

export function createFigureForGeofence(
  geofence: Geofence,
): google.maps.Rectangle | google.maps.Polygon {
  if (!geofence || !geofence.geometry || !geofence.type) {
    console.error('Invalid geofence object:', JSON.stringify(geofence, null, 2))
    throw new Error('Invalid geofence object')
  }
  if (geofence.type === 'Polygon') {
    return new google.maps.Polygon({
      paths: geofence.geometry.coordinates[0].map(
        (coord: any) => new google.maps.LatLng(coord[1], coord[0]),
      ),
    })
  } else {
    // Assuming Rectangle
    const [[sw_lng, sw_lat], , [ne_lng, ne_lat]] =
      geofence.geometry.coordinates[0]
    return new google.maps.Rectangle({
      bounds: new google.maps.LatLngBounds(
        new google.maps.LatLng(sw_lat, sw_lng),
        new google.maps.LatLng(ne_lat, ne_lng),
      ),
    })
  }
}
