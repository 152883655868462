import React, { useState } from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Icon,
  IconButton,
} from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { menuItems } from './menuItems' // Move menuItems to a separate file
// ... existing imports ...
import { styled, Theme, CSSObject } from '@mui/material/styles'

const drawerWidth = 160

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const topBarHeight = 0 // Adjust this value to match your TopBar height

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      marginTop: `${topBarHeight}px`, // Add this line
      height: `calc(100% - ${topBarHeight}px)`, // Add this line
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      marginTop: `${topBarHeight}px`, // Add this line
      height: `calc(100% - ${topBarHeight}px)`, // Add this line
    },
  }),
}))

interface SideNavProps {
  open: boolean
  onDrawerToggle: () => void
}
const SideNav = (props: SideNavProps) => {
  const navigate = useNavigate()
  const [selected, setSelected] = useState<number>()

  return (
    <StyledDrawer variant="permanent" open={props.open}>
      <IconButton
        onClick={() => {
          props.onDrawerToggle()
        }}
        sx={{ alignSelf: 'flex-end', m: 1 }}
      >
        <Icon>{props.open ? 'chevron_left' : 'chevron_right'}</Icon>
      </IconButton>
      <List>
        {menuItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={index === selected}
              onClick={() => {
                setSelected(index)
                navigate(item.to)
              }}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                // px: 2.5,
              }}
            >
              <Icon
                color="primary"
                sx={{ marginRight: props.open ? 2 : 'auto', minWidth: 0 }}
              >
                {item.iconClassName}
              </Icon>
              <ListItemText
                primaryTypographyProps={{ fontSize: '14px' }}
                primary={item.name}
                sx={{ opacity: props.open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </StyledDrawer>
  )
}

export default SideNav
